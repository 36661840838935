@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');

.galeryContainer {
    max-width: 100%;
    height: 258px;
    width: 100%;
    padding: 80px 0;
    pointer-events: none;
    background-image: url(/src/assets/Fondo2.png);
    background-repeat: no-repeat;
    background-attachment:scroll;
    background-position: center;

}

@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');


.CaracteristicasContainer{
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
    margin: 0px;
    height: 100%;
    background-color: white;
}
.generalTextContainer {
    max-width: 100%;
    padding: 0 15%;
    pointer-events: none;
}



.titleContainer {
    text-align: left;
}
.s1{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    background: -webkit-linear-gradient(#f78c45, #e53856);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 1rem;
}

.t1{
    text-align: left;
    color: rgb(0, 0, 0);
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    padding-bottom: 2rem;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');

.footerContainer {
    box-sizing: border-box;
    background-color: #060606;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.footerCard {
    padding-top: 50px;
    max-width: 900px;
}

.footerImg {
    padding-top: 50px;
    display: flex;
}

@media screen and (max-width:1023px) {
    .footerImg {
        align-items: center;
    }
}

.footerImg img {
    padding-left: 50px;
    max-height: 350px;
}

@media screen and (max-width:1023px) {
    .footerImg img {
        padding-left: 50px;
        max-height: 250px;
        max-width: 250px;
    }
}

@media screen and (max-width:481px) {
    .footerImg img {
        display: none;
    }
}

.footerText {
    text-decoration: solid;
    color: rgb(214, 214, 214);
    font-family: 'Inter', sans-serif;
    background-color: #060606;
    font-weight: 100;
    font-size: 14px;
    text-align: center;
    padding: 0px 0px 20px 0px;
}

@media screen and (max-width:1023px) {
    .footerText {
        font-size: 10px;

    }
}

.footerTitle {
    font-size: 3em;
    font-weight: 100;
    color: #c4c4c4;
    font-family: 'Inter', sans-serif;
}

@media screen and (max-width:1023px) {
    .footerTitle {
        font-size: 2em;
    }
}

.hrFooter {
    margin-top: 20px;
    height: 1px;
    width: 500px;
    border: none;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(rgb(250, 107, 12)), to(transparent), color-stop(50%, rgb(229, 59, 85)));
}

@media screen and (max-width:1023px) {
    .hrFooter {
        max-width: 250px;
    }
}

.footerSub {
    font-size: 1em;
    font-weight: 100;
    color: #c4c4c4;
    font-family: 'Inter', sans-serif;
    padding-bottom: 4px;
}

@media screen and (max-width:1023px) {
    .footerSub {
        font-size: 0.8em;

    }
}

.footerSubIg {
    font-size: 1em;
    font-weight: 100;
    color: #c4c4c4;
    font-family: 'Inter', sans-serif;
    padding-bottom: 4px;
    cursor: pointer;
    transition: 1s;
}

.footerSubIg:hover {
    transition: 1s;
    color: #646464;
}

@media screen and (max-width:1023px) {
    .footerSubIg {
        font-size: 0.8em;
    }

    .footerSubIg:hover {
        color: #c4c4c4;
    }
}

.linkIg {
    text-decoration: none;
}

.footerSubTitle {
    font-size: 1em;
    font-weight: 400;
    color: #c4c4c4;
    font-family: 'Inter', sans-serif;
    padding-right: 180px;
    padding-bottom: 10px;
}

.footerSubTitleRed {
    font-size: 1em;
    font-weight: 400;
    color: #c4c4c4;
    font-family: 'Inter', sans-serif;
    padding-right: 0;
    padding-bottom: 10px;
}

@media screen and (max-width:1023px) {
    .footerSubTitle {
        font-size: 0.8em;
        padding-right: 100px;
    }
}

.contactLink {
    text-decoration: none;
    font-size: 1em;
    font-weight: 100;
    color: #d1d1d1;
    font-family: 'Inter', sans-serif;
    padding-bottom: 4px;
    transition: 1s;
}

.contactLink:hover {
    transition: 1s;
    color: #646464;
}

@media screen and (max-width:1023px) {
    .contactLink:hover {
        color: #c4c4c4;
    }
}

.subCard {
    display: flex;
    padding: 50px 20%;
    width: 100%;

}

@media screen and (max-width: 1023px) {
    .subCard {
        display: block;
        width: 70%;
        padding-left: 10%;
    }
}

@media screen and (max-width: 1023px) {
    .subCarText {
        padding-bottom: 20px;
    }
}

.footerHeader {
    padding: 0px 20%;
}

@media screen and (max-width: 1023px) {
    .footerHeader {
        padding-left: 5%;
        padding-right: 0;
    }
}
@import url('https://fonts.cdnfonts.com/css/gilroy-bold%27');


.ServiciosContainer {
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
    margin: 0px;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
}
.generalTextContainer {
    padding: 0 15%;
    pointer-events: none;
}
@media screen and (max-width:1023px) {
    .generalTextContainer {
        padding: 0 0;
        width: 100%;
    }
}
.titleContainer {
    text-align: left;
}
@media screen and (max-width:1023px) {
    .titleContainer{
        text-align: center;
    }
}
.titleContainer .p1 {
    font-size: 14px;
    font-weight: 400;
    color: #00000080;
    padding-bottom: 20px;
}
@media screen and (max-width:1023px) {
    .titleContainer .p1 {
        padding-bottom: 50px;
        text-align: left;
    }
}
.titleContainer .p1Left {
    font-family: 'Kanit', sans-serif;
    padding-right: 2px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 100;
    color: rgb(160, 160, 160);
  }
.cardContainer {
    display: flex;
    width: 100%;
}
@media screen and (max-width:1023px) {
    .cardContainer {
        display: block;   
        text-align: center; 
        
    }
}
@media screen and (max-width: 481px) {
    .cardContainer {
        display: block;   
        text-align: center; 
        
    }
}
.boxContainer {
    padding-right: 40px;
    width: 500px;
}
@media screen and (max-width:1023px) {
    .boxContainer {
        width: 400px;  
        margin: 0px;    
        margin-left: 20%;
    }
}
@media screen and (max-width:750px) {
    .boxContainer {
        width: 300px;  
        margin: 0px;    
        margin-left: 20%;

    }
}
@media screen and (max-width:600px) {
    .boxContainer {
        width: 300px;  
        margin: 0px;    
        margin-left: 10%;

    }
}
@media screen and (max-width:481px) {
    .boxContainer {
        width: auto;  
        width: 250px;  


    }
}
.ServiciosImg{
    max-width: 40%;
    opacity: 85%;
}
@media screen and (max-width:1023px) {
    .ServiciosImg {
        max-width: 200px;
        padding-left: 10%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Golos+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,300;0,800;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');


h1 {
    color: #5e5e5e;
    font-family: 'Roboto', sans-serif;
}


h1:hover {
    cursor: pointer;
}

.generalContainer{
    width: 100%;
    box-sizing: border-box;
    padding: 0px 0px 0px 80px;
    background-color: #060606;
}
@media screen and (max-width:1023px) {
    .generalContainer {
        padding: 0px 0px 0px 40px;
    }
}
.sloganContainer {
    width: 100%;
    min-height: 93vh;
    display: flex;
    position: relative;
    justify-content: left;
    align-items: center;
    transition: 1.1s;
}

.slogan {
    position: absolute;
    letter-spacing: 5px;
    font-size: 20px;
    margin-left: 8px;
    font-weight: 300;
    color: #c4c4c4;
    font-family: 'Inter', sans-serif;
}

@media screen and (max-width:1023px) {
    .slogan {
        font-size: 14px;
    }
}

.slogaSubtitle {
    font-size: 7em;
    font-weight: 100;
    color: #c4c4c4;
    margin-bottom: 140px;
    font-family: 'Inter', sans-serif;
}

@media screen and (max-width:1023px) {
    .slogaSubtitle {
        font-size: 3em;
        margin-bottom: 100px;
    }
}

.generalContainer .homeImg{
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
}


.generalContainer .partnerImg {
    position: absolute;
    max-width: 100%;
    margin-top: 400px;
    opacity: 70%;
}

@media screen and (max-width:1023px) {
    .generalContainer .partnerImg {
        max-width: 70%;
        margin-top: 200px;
    }
}

.homeDivisor{
    height: 6px;
    border: none;
    width: 100%;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(rgb(250, 107, 12)), color-stop(50%, rgb(229, 59, 85)));
}